import { useEffect } from "react";
import LoginComponent from "./pages/login/Login.component";
import Alert from "./shared/alert/Alert.component";
import { authLoading$, checkApiKeyValid, getAuthLoading, isLoggedIn, isLoggedIn$ } from "./shared/auth-state/auth.state";
import Loading from "./shared/loading/Loading.component";
import NavigationComponent from "./shared/navigation/Navigation.component";
import RouterComponent, { route$ } from "./shared/router/Router.component";
import { useLocalState } from "./util/use-local-state";

import 'primereact/resources/themes/lara-light-blue/theme.css';

function App() {

  const loggedIn = useLocalState<boolean>(isLoggedIn$, isLoggedIn());
  const loading = useLocalState<boolean>(authLoading$, getAuthLoading());
  const route = useLocalState<string>(route$, window.location.hash);

  useEffect(() => { 
    window.addEventListener("hashchange", () => {
        route$.next(window.location.hash);
    });
    checkApiKeyValid();
   }, []);

  if (route === "#/register") {
    return <>
    <Alert />
    {loading && <Loading />}<div style={{ position: "absolute", top: 0, bottom: 0, right: 0, left: 0, display: "flex", alignItems: "center", justifyContent: "center" }}>
      <div><RouterComponent /></div>
    </div></>;
  }

  return (
    <>
      <Alert />
      {loading && <Loading />}
      { loggedIn ?
        <>
        <div style={{ height: "100vh" }}>
          <div style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "48px", backgroundColor: "var(--clr-pri)" }}><NavigationComponent /></div>
          <div style={{ paddingTop: "48px", left: 0, width: "100%", height: "100vh", display: "flex", flexDirection: "column" }}>
            <div style={{ flex: 1, maxWidth: "100vw", wordBreak: "break-all" }}><RouterComponent /></div>
          </div>
        </div>
        </>
      : <LoginComponent /> }
    </>
  );
}

export default App;
