import { BehaviorSubject, Observable, map } from "rxjs";
import { User } from "../../users/state/users.state";

export interface RidingLesson {
    uid?: string;
    teacher: string;
    description: string;
    date: Date;
    start: string;
    end: string;
    price: number;
    slotsHorse: number;
    slotsPony: number;
    bookedSlotsHorse: number;
    bookedSlotsPony: number;
    usersHorse: User[];
    usersPony: User[];
}

interface RidingLessonState {
    lessons: RidingLesson[];
    activeLesson?: RidingLesson | null;
    detailLesson?: RidingLesson | null;
    deleteLesson?: RidingLesson | null;
    loading?: boolean;
}

const ridingLessonsState$ = new BehaviorSubject<RidingLessonState>({ lessons: [] });

// loading indicator
export const adminLoading$ = ridingLessonsState$.pipe(map((state) => !!state?.loading));
export function getAdminLoading() { return !!ridingLessonsState$?.getValue()?.loading; }

// all lessons
export function updateAllLessons(lessons: RidingLesson[]) {
    const state = { ...ridingLessonsState$.getValue(), ...{ lessons } };
    ridingLessonsState$.next(state);
}
export function upsertLesson(lesson: RidingLesson) {
    const _state = ridingLessonsState$.getValue();
    if (_state?.lessons?.some((l) => l?.uid === lesson?.uid)) {
        const state = { ..._state, ...{ lessons: _state?.lessons?.map((l) => l?.uid === lesson?.uid ? lesson : l) } };
        ridingLessonsState$.next(state);
    }  else {
        const state = { ..._state, ...{ lessons: [..._state?.lessons, lesson] } };
        ridingLessonsState$.next(state);
    }
}
export const allLessons$: Observable<RidingLesson[]> = ridingLessonsState$.pipe(map((state) => state?.lessons));
export function getAllLessons(): RidingLesson[] { return ridingLessonsState$?.getValue()?.lessons; }

export function deleteLesson(lesson: RidingLesson) {
    const _state = ridingLessonsState$.getValue();
    const state = { ..._state, ...{ lessons: _state?.lessons?.filter((l) => l?.uid !== lesson?.uid) } };
    ridingLessonsState$.next(state);
}

// active lesson
export function setActiveLesson(activeLesson: RidingLesson | null) {
    const state = { ...ridingLessonsState$.getValue(), ...{ activeLesson } };
    ridingLessonsState$.next(state);
}
export const activeLesson$: Observable<RidingLesson | undefined | null> = ridingLessonsState$.pipe(map((state) => state?.activeLesson));
export function getActiveLesson(): RidingLesson | undefined | null { return ridingLessonsState$?.getValue()?.activeLesson; }

// detail lesson
export function setDetailLesson(detailLesson: RidingLesson | null) {
    const state = { ...ridingLessonsState$.getValue(), ...{ detailLesson } };
    ridingLessonsState$.next(state);
}
export const detailLesson$: Observable<RidingLesson | undefined | null> = ridingLessonsState$.pipe(map((state) => state?.detailLesson));
export function getDetailLesson(): RidingLesson | undefined | null { return ridingLessonsState$?.getValue()?.detailLesson; }

// delete lesson
export function setDeleteLesson(deleteLesson: RidingLesson | null) {
    const state = { ...ridingLessonsState$.getValue(), ...{ deleteLesson } };
    ridingLessonsState$.next(state);
}
export const deleteLesson$: Observable<RidingLesson | undefined | null> = ridingLessonsState$.pipe(map((state) => state?.deleteLesson));
export function getDeleteLesson(): RidingLesson | undefined | null { return ridingLessonsState$?.getValue()?.deleteLesson; }
