import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useState } from 'react';
import { loginRequest } from '../../api/login.request';
        
export function LoginComponent() {

    /* COMPONENT STATE */
    const [email, setEmail] = useState<string>("");
    const [pwd, setPwd] = useState<string>("");

    /* COMPONENT LOGIC */
    function handleLogIn() {
        loginRequest(email, pwd);
    }

    /* COMPONENT TEMPLATE */
    return(<div style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div className="card" style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <h3 style={{ marginBottom: "8px" }}>Reitschule Hess</h3>
            <InputText value={email} onChange={(e) => setEmail(e?.target?.value)} placeholder="E-Mail" required type="text" />
            <InputText value={pwd} onChange={(e) => setPwd(e?.target?.value)} placeholder="Passwort" required type="password" />
            <Button label="Anmelden" disabled={email?.length < 1 || pwd?.length < 1} onClick={handleLogIn} />
            <a href="#/register">Registrieren</a>
        </div>
    </div>);
}

export default LoginComponent