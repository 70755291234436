import { useEffect, useState } from "react";
import { BehaviorSubject, Observable, tap } from "rxjs";

export function useLocalState<T>(state$: BehaviorSubject<T> | Observable<T>, initValue: T) {
    const [s, setS]: [T | undefined, Function] = useState(initValue);
    useEffect(() => {
        const sub = state$.pipe(tap((s) => setS(s))).subscribe();
        return () => { sub.unsubscribe(); }
    }, [state$]);
    return s;
}