import { RidingLesson, deleteLesson } from "../pages/riding-lessons/state/riding-lessons.state";
import { getApiKey } from "../shared/auth-state/auth.state";
import { server } from "./server";

export async function deleteLessonFromBe(lesson: RidingLesson) {
    const xApiKey = getApiKey();
    if (!xApiKey) { console.error("no api key"); return; }
    const url = server + "/lessons/delete-lesson.php"
    const headers = new Headers();
    headers.append("x-api-key", xApiKey);
    try {
        const r = await fetch(url, { method: "DELETE", headers, body: JSON.stringify({ uid: lesson?.uid }) });
        const resp = await r.json();
        deleteLesson(resp);
    } catch (_) {
        return;
    }
}