import { msg$ } from "../shared/alert/Alert.component";
import { updateUser } from "../shared/auth-state/auth.state";
import { server } from "./server";

export async function loginRequest(email: string, password: string) {
    const url = server + "/auth/login.php"
    try {
        const r = await fetch(url, { method: "POST", body: JSON.stringify({ email: email?.toLowerCase(), password }) });
        const resp = await r.json();
        if (!resp?.uid) throw new Error();
        updateUser(resp);
    } catch (_) {
        msg$.next({ type: "warn", header: "Login fehlgeschlagen!" })
        return;
    }
}