import { getApiKey } from "../shared/auth-state/auth.state";
import { server } from "./server";

export async function postChangePasswordInBe(old: string, new1: string, new2: string) {
    const xApiKey = getApiKey();
    if (!xApiKey) { console.error("no api key"); return; }
    const url = server + "/auth/post-change-password.php"
    const headers = new Headers();
    headers.append("x-api-key", xApiKey);
    const r = await fetch(url, { method: "POST", headers, body: JSON.stringify({ old, new1, new2 }) });
    return (r?.status <= 204);
}