import { Button } from "primereact/button"
import { AuthState, activeAuthUser$, getActiveAuthUser } from "../../shared/auth-state/auth.state"
import { useLocalState } from "../../util/use-local-state"

export function MyProfileComponent() {

    const user = useLocalState<AuthState>(activeAuthUser$, getActiveAuthUser())

    return (
    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <div className="card" style={{ display: "flex", gap: "8px" }}>
            <div>
                <p style={{ fontWeight: "bold" }}>Name:</p>
                <p style={{ fontWeight: "bold" }}>Email:</p>
            </div>
            <div style={{ flex: 1 }}>
                <p>{user?.firstname} {user?.lastname}</p>
                <p>{user?.email}</p>
            </div>
        </div>
        <div>
            <Button>
                <a href={`#/change-password`} style={{ color: "white", textDecoration: "none", display: "flex", alignItems: "center", gap: "8px" }}>
                    <span>Passwort ändern</span>
                </a>
            </Button>
        </div>
    </div>
    )
}

export default MyProfileComponent