import { format } from "date-fns";
import { RidingLesson, upsertLesson } from "../pages/riding-lessons/state/riding-lessons.state";
import { getApiKey } from "../shared/auth-state/auth.state";
import { server } from "./server";

export async function putLessonToBe(lesson: RidingLesson) {
    console.log(lesson);
    const xApiKey = getApiKey();
    if (!xApiKey) { console.error("no api key"); return; }
    const url = server + "/lessons/put-lesson.php"
    const headers = new Headers();
    headers.append("x-api-key", xApiKey);
    const r = await fetch(url, { method: "PUT", headers, body: JSON.stringify({ ...lesson, ...{ date: format(new Date(lesson?.date), "yyyy-MM-dd")}}) });
    const resp = await r.json();
    upsertLesson(resp);
}