import { useEffect, useState } from "react";
import { useLocalState } from "../../../../util/use-local-state";
import { RidingLesson, activeLesson$, getActiveLesson, setActiveLesson } from "../../state/riding-lessons.state";
import { putLessonToBe } from "../../../../api/put-lesson.request";
import { format, isBefore } from "date-fns";
import { InputText } from "primereact/inputtext";
import TimeDropDownComponent from "../../../../shared/time-dropdown/TimeDropDown.component";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";

export function LessonDialogAdminComponent() {

    const lesson = useLocalState<RidingLesson | undefined | null>(activeLesson$, getActiveLesson());

    useEffect(() => { if (!lesson) { window.location.hash = "/riding-lessons"; } return () => { setActiveLesson(null); }}, [lesson]);
    
    if (!lesson) { window.location.hash = "/riding-lessons"; }

    /* COMPONENT STATE */
    const [_lesson, set_Lesson] = useState<RidingLesson>({ ...lesson as RidingLesson, ...{ date: new Date(lesson?.date as Date) } });

    /* COMPONENT LOGIC */
    function formInvalid(lesson: RidingLesson): string[] {
        function timeInvalid(start: string, end: string): boolean {
            const [hh1, mm1] = start?.split(":");
            const [hh2, mm2] = end?.split(":");
            if (hh1 < hh2) return false;
            if (hh1 === hh2 && mm1 < mm2) return false;
            return true;
        }
        let err: string[] = [];
        if (lesson?.teacher?.length < 1) err.push("Kein Lehrer angegeben");
        if (lesson?.slotsHorse < 1 && lesson?.slotsPony < 1) err.push("Keine Plätze angegeben");
        if (timeInvalid(lesson?.start, lesson?.end)) err.push("Zeiten nicht erlaubt")
        return err;
    }
    function handleSaveClick() {
        if (!_lesson) return;
        putLessonToBe(_lesson).then(() => window.location.hash = "/riding-lessons");
    }

/* COMPONENT TEMPLATE */
return (
    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
        <InputText style={{ width: "100%" }} value={format(_lesson?.date, "yyyy-MM-dd")} type="date" lang="de" onChange={((e) => set_Lesson(({ ..._lesson, ...{ date: new Date(e?.target?.value) }})))} />
            <TimeDropDownComponent hh={_lesson?.start?.split(":")?.[0]} mm={_lesson?.start?.split(":")?.[1]} onChange={(hh, mm) => set_Lesson({ ..._lesson, ...{ start: `${hh}:${mm}` }})} />
            <TimeDropDownComponent hh={_lesson?.end?.split(":")?.[0]} mm={_lesson?.end?.split(":")?.[1]} onChange={(hh, mm) => set_Lesson({ ..._lesson, ...{ end: `${hh}:${mm}` }})} />
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <InputText style={{ width: "100%" }} placeholder="Lehrer" value={_lesson?.teacher} onChange={(e) => set_Lesson({..._lesson, ...{ teacher: e?.target?.value }})} />
            <InputText style={{ width: "100%" }} placeholder="Beschreibung" value={_lesson?.description} onChange={(e) => set_Lesson({..._lesson, ...{ description: e?.target?.value }})} />
            <InputNumber style={{ width: "100%" }} min={0} value={_lesson?.price} onChange={(e) => set_Lesson({..._lesson, ...{ price: e?.value ?? 0 }})} />
            <div>Pferde: <InputNumber style={{ width: "100%" }} min={_lesson?.usersHorse?.length} value={_lesson?.slotsHorse} onChange={(e) => set_Lesson({..._lesson, ...{ slotsHorse: e?.value ?? 0 }})} /></div>
            <div>Ponys: <InputNumber style={{ width: "100%" }} min={_lesson?.usersPony?.length} value={_lesson?.slotsPony} onChange={(e) => set_Lesson({..._lesson, ...{ slotsPony: e?.value ?? 0 }})} /></div>
        </div>
        <div>
            <Button disabled={formInvalid(_lesson)?.length > 0} label="Speichern" onClick={handleSaveClick} />
            <div style={{ marginTop: "8px" }}>
                {formInvalid(_lesson)?.map((err, key) => <p key={key} style={{color: "red"}}>{err}</p>)}
            </div>
        </div>
    </div>
);
}

export default LessonDialogAdminComponent;