import { useEffect, useState } from "react";
import { useLocalState } from "../../../util/use-local-state";
import { User, activeUser$, getActieUser, getAllUsers, setActiveUser } from "../state/users.state";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { postActivateUser } from "../../../api/post-active-user.request";
import { msg$ } from "../../../shared/alert/Alert.component";
import { getUsersFromBe } from "../../../api/get-users.request";
import { postChangeNewUserPwd } from "../../../api/post-new-user-pwd.request";
import Loading from "../../../shared/loading/Loading.component";


export function UserEditComponent() {

    const user = useLocalState<User | undefined | null>(activeUser$, getActieUser());
    const [pwd, setPwd] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => { if (!user) { window.location.hash = "/users"; } return () => { setActiveUser(null); }}, [user]);

    function handleActivateUserClick() {
        if (!user?.uid) return;
        setLoading(true);
        postActivateUser(user?.uid).then((e) => {
            if (e) {
                msg$.next({ type: "success", header: "User efolgreich aktiviert!" });
                getUsersFromBe().then(() => {
                    const _user = (getAllUsers() ?? [])?.find((u) => user?.uid === u?.uid);
                    if (_user?.uid) {
                        setActiveUser(_user);
                    }
                })
            } else {
                msg$.next({ type: "warn", header: "User konnte nicht aktiviert werden!" });
            }
        }).finally(() => setLoading(false));
    }

    function handleBtnClick() {
        if (!user?.uid) return;
        setLoading(true);
        postChangeNewUserPwd(pwd, user?.uid).then((e) => {
            if (e) {
                msg$.next({ type: "success", header: "User Passwort efolgreich geändert!" });
            } else {
                msg$.next({ type: "warn", header: "User Passwort konnte nicht geändert werden!" });
            }
        }).finally(() => setLoading(false));
    }

    return (
        <>
        { loading && <Loading />}
        <div style={{ display: "flex", gap: "8px", marginBottom: "8px" }}>
            <div>
                <p>Name</p>
                <p>Email</p>
            </div>
            <div>
                <p>{user?.firstName} {user?.lastName}</p>
                <p>{user?.email}</p>
            </div>
        </div>
        {!user?.active && <>
            <hr />
            <div style={{ margin: "8px 0" }}>
                <Button label="Nutzer aktivieren" onClick={handleActivateUserClick} />
            </div>
        </>}
        <hr />
        <div style={{ marginTop: "8px" }}>
            <div style={{ marginBottom: "4px" }}><InputText placeholder="Neues Passwort vergeben" value={pwd} onChange={(e) => setPwd(e?.target?.value)} style={{ width: "100%" }} /></div>
            <div><Button label="Speichern" onClick={handleBtnClick} /></div>
        </div>
    </>);
}

export default UserEditComponent;