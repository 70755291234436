import { useEffect } from "react";
import { useLocalState } from "../../../../util/use-local-state";
import { RidingLesson, detailLesson$, getDetailLesson, setDeleteLesson } from "../../state/riding-lessons.state";
import { format } from "date-fns";

export function LessonUserAdminComponent() {

    const lesson = useLocalState<RidingLesson | undefined | null>(detailLesson$, getDetailLesson());

    useEffect(() => { if (!lesson) { window.location.hash = "/riding-lessons"; } return () => { setDeleteLesson(null); }}, [lesson]);

    /* COMPONENT LOGIC */

    return (
        <div>    
            {lesson && 
            <>
                <div className="card" style={{  marginTop: "16px", flex: 1, display: "flex", flexDirection: "column", gap: "8px" }} >
                    <span style={{ fontWeight: "bold" }}>{format(new Date(lesson?.date), "dd.MM.yyyy")} {lesson?.start}-{lesson?.end}</span>
                    <span>{lesson?.teacher}</span>
                    <span>Preis: {lesson?.price} €</span>
                    <span>Anmeldungen: {(lesson?.usersHorse?.length ?? 0) + (lesson?.usersPony?.length ?? 0)} / {parseInt(`${lesson?.slotsHorse ?? 0}`) + parseInt(`${lesson?.slotsPony ?? 0}`)}</span>
                </div>
                <hr style={{ margin: "4px 0" }} />
                <div className="card">
                    <h4>Pferde Anmeldungen</h4>
                    {lesson?.usersHorse?.map((user, i) => <p key={i}>{i+1}. {user?.firstName} {user?.lastName}</p>)}
                </div>
                <div className="card">
                    <h4>Pony Anmeldungen</h4>
                    {lesson?.usersPony?.map((user, i) => <p key={i}>{i+1}. {user?.firstName} {user?.lastName}</p>)}
                </div>
            </>
            }
        </div>
    );
}

export default LessonUserAdminComponent;