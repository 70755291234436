import { BehaviorSubject, Observable, map } from "rxjs";

export interface User {
    uid?: string;
    email: string;
    firstName: string;
    lastName: string;
    role: string;
    active: boolean;
}

interface UsersState {
    users: User[];
    activeUser?: User | null;
}

const usersState$ = new BehaviorSubject<UsersState>({ users: [] });

// all users
export function updateAllUsers(users: User[]) {
    const state = { ...usersState$.getValue(), ...{ users: users?.sort((a, b) => a?.firstName > b?.firstName ? 1 : -1) } };
    usersState$.next(state);
}
export const allUsers$: Observable<User[]> = usersState$.pipe(map((state) => state?.users));
export function getAllUsers(): User[] { return usersState$?.getValue()?.users; }

// active user
export function setActiveUser(activeUser: User | null) {
    const state = { ...usersState$.getValue(), ...{ activeUser } };
    usersState$.next(state);
}
export const activeUser$: Observable<User | undefined | null> = usersState$.pipe(map((state) => state?.activeUser));
export function getActieUser(): User  | undefined | null { return usersState$?.getValue()?.activeUser; }