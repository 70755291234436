import { useEffect, useState } from "react";
//import HomeComponent from "../../pages/home/Home.component";
import RidingLessonsComponent from "../../pages/riding-lessons/RidingLessons.component";
import MyProfileComponent from "../../pages/my-profile/MyProfile.component";
import UsersComponent from "../../pages/users/Users.component";
import LessonDialogAdminComponent from "../../pages/riding-lessons/admin-view/lesson-dialog/LessonDialog.component";
import LessonDeleteAdminComponent from "../../pages/riding-lessons/admin-view/lesson-delete/LessonDelete.component";
import LessonNewAdminComponent from "../../pages/riding-lessons/admin-view/lesson-new/LessonNew.component";
import LessonUserAdminComponent from "../../pages/riding-lessons/admin-view/lesson-user/LessonUser.component";
import { BehaviorSubject } from "rxjs";
import ChangePasswordComponent from "../../pages/my-profile/change-password/ChangePassword.component";
import UserEditComponent from "../../pages/users/user-edit/UserEdit.component";
import RegisterComponent from "../../pages/register/Register.component";
import ChangeRegisterPwdComponent from "../../pages/users/change-register-pwd/ChangeRegisterPwd.component";
import { getActiveAuthUser } from "../auth-state/auth.state";

const routes = new Map(Object.entries({
    "": {component: <RidingLessonsComponent />, roles: []},
    "#/users": {component: <UsersComponent />, roles: ["TEACHER", "ADMIN"]},
    "#/user-edit": {component: <UserEditComponent />, roles: ["TEACHER", "ADMIN"]},
    "#/riding-lessons": {component: <RidingLessonsComponent />, roles: []},
    "#/riding-lesson/add": {component: <LessonNewAdminComponent />, roles: ["TEACHER", "ADMIN"]},
    "#/riding-lesson/detail": {component: <LessonDialogAdminComponent />, roles: ["TEACHER", "ADMIN"]},
    "#/riding-lesson/users": {component: <LessonUserAdminComponent />, roles: ["TEACHER", "ADMIN"]},
    "#/riding-lesson/delete": {component: <LessonDeleteAdminComponent />, roles: ["TEACHER", "ADMIN"]},
    "#/my-profile": {component: <MyProfileComponent />, roles: []},
    "#/change-password": {component: <ChangePasswordComponent />, roles: []},
    "#/register": {component: <RegisterComponent />, roles: []},
    "#/change-register-pwd": {component: <ChangeRegisterPwdComponent />, roles: ["TEACHER", "ADMIN"]},
}));

export const route$ = new BehaviorSubject<string>(window.location.hash);

export function RouterComponent() {

    /* COMPONENT STATE */
    const [elem, setElem] = useState<React.ReactNode>(routes?.get(window.location.hash)?.component ?? <>Error 404 - Route not found</>)

    /* COMPONENT INIT */
    useEffect(() => {
        const sub = route$.subscribe(() => {
            const authUser = getActiveAuthUser()?.userLvl ?? "NONE";
            if ((routes?.get(window.location.hash)?.roles ?? [])?.length > 0 && !((routes?.get(window.location.hash)?.roles ?? [])?.includes(authUser))) { 
                window.location.hash = "";
                return;
            }
            setElem(routes?.get(window.location.hash)?.component ?? <>Error 404 - Route not found</>);
        });
        return () => { sub.unsubscribe(); }
    }, []);

    /* COMPONENT TEMPLATE */
    return (<div style={{ marginTop: "8px", padding: "0 8px" }}>{elem}</div>);
}

export default RouterComponent