import React, { useState } from 'react';
import menuIcon from '../../assets/icon_menu.svg';
import logoutIcon from '../../assets/icon_logout.svg';
import { getUserRole, updateUser, userRole$ } from '../auth-state/auth.state';
import { useLocalState } from '../../util/use-local-state';

export function NavigationComponent() {

    /* COMPONENT DATA */
    const routes = [
        /*{
            label: "Startseite",
            ref: "/home",
            roles: []
        },*/
        {
            label: "Reitschüler",
            ref: "/users",
            roles: ["ADMIN", "TEACHER"]
        },
        {
            label: "Reitunterricht",
            ref: "/riding-lessons",
            roles: []
        },
        {
            label: null,
            ref: "/riding-lesson/detail",
            roles: []
        },
        {
            label: "Mein Profil",
            ref: "/my-profile",
            roles: []
        }
    ];

    /* COMPONENT STATE */
    const [showNav, setShowNav] = useState<boolean>(false);
    const role = useLocalState<string | undefined>(userRole$, getUserRole());

    /* COMPONENT LOGIC */
    function handleMenuIconClick() {
        setShowNav(!showNav);
    }
    function handleLogoutIconClick() {
        updateUser({});
    }

    /* COMPONENT STYLES */
    const s_navCont: React.CSSProperties = { ...{
        position: "absolute",
        backgroundColor: "black",
        left: 0,
        top: "48px",
        width: "100%",
        height: "calc(100vh - 48px)",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        alignItems: "center",
        padding: "16px 8px",
        transition: "300ms all ease-in-out",
        opacity: 0.9
    }, ...showNav ? {} : {
        transform: "translateX(-120%)",
        opacity: 0
    }};

    const s_navLink: React.CSSProperties = {
        textDecoration: "none",
        color: "white",
        fontSize: "24px"
    };

    /* COMPONENT TEMPLATE */
    return (<nav style={{ height: "48px" }}>
        <div style={{ width: "100%", height: "48px", display: "flex", padding: "0 8px" }}>
            <img src={menuIcon} alt="menu-icon" onClick={handleMenuIconClick} />
            <span style={{ flex: 1 }}></span>
            <img src={logoutIcon} alt="logout-icon" onClick={handleLogoutIconClick} />
        </div>
        <div style={s_navCont}>
            {routes?.map((route, i) => (
                route && route?.label && (route?.roles?.length === 0 || route?.roles?.includes(role ?? "UNKNOWN"))
                ? <a key={i} style={s_navLink} onClick={() => setShowNav(false)} href={`#${route?.ref}`}>{route?.label}</a>
                : null
            ))}
        </div>
    </nav>);
}

export default NavigationComponent