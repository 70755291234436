import { Button } from "primereact/button";
import { useLocalState } from "../../../util/use-local-state";
import { RidingLesson, adminLoading$, allLessons$, getAdminLoading, getAllLessons, setActiveLesson, setDeleteLesson, setDetailLesson } from "../state/riding-lessons.state";
import { useEffect } from "react";
import { getLessonsFromBe } from "../../../api/get-lessons.request";
import { format } from "date-fns";
import detailIcon from '../../../assets/icon_detail.svg';
import editIcon from '../../../assets/icon_edit.svg';
import deleteIcon from '../../../assets/icon_delete.svg';
import addIcon from '../../../assets/icon_add.svg';
import Loading from "../../../shared/loading/Loading.component";
import { WEEKDAYS_SHORT } from "../../../shared/weekdays/weekdays";


export function AdminViewComponent() {

    const lessons = useLocalState<RidingLesson[]>(allLessons$, getAllLessons());
    const loading = useLocalState<boolean>(adminLoading$, getAdminLoading());
    
    useEffect(() => {
        getLessonsFromBe();
    }, []);

    return (
        <>
        {loading && <Loading />}
        <div>
            <div>
                <Button>
                    <a href={`#/riding-lesson/add`} style={{ color: "white", textDecoration: "none", display: "flex", alignItems: "center", gap: "8px" }}>
                            <img src={addIcon} alt="add-icon" style={{ height: "24px", width: "24px" }} /> <span>Hinzufügen</span>
                    </a>
                </Button>
            </div>
            <hr style={{ margin: "4px 0" }}/>
            <div>
                {lessons?.sort((a, b) => new Date(a?.date ?? "") > new Date(b?.date ?? "") ? 1 : -1)?.map((lesson, i) => (
                <>
                <div style={{ display: "flex", gap: "8px", margin: "8px 0" }}>
                    <div key={i} style={{ flex: 1, display: "flex", flexDirection: "column", gap: "8px" }} >
                        <span style={{ fontWeight: "bold" }}>{format(new Date(lesson?.date), "dd.MM.yyyy")} {lesson?.start}-{lesson?.end} ({WEEKDAYS_SHORT?.[new Date(lesson?.date)?.getDay()]})</span>
                        <span>{lesson?.teacher}</span>
                        <span>{lesson?.description}</span>
                        <span>Anmeldungen Pferd: {lesson?.usersHorse?.length ?? 0} / {lesson?.slotsHorse}</span>
                        <span>Anmeldungen Pony: {lesson?.usersPony?.length ?? 0} / {lesson?.slotsPony}</span>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "8px" }}>
                    <a href={`#/riding-lesson/users`}>
                        <img onClick={(e) => {
                            setDetailLesson(lesson);
                        }} src={detailIcon} alt="detail-icon" style={{ height: "24px", width: "24px" }} />
                    </a>
                    <a href={`#/riding-lesson/detail`}>
                        <img onClick={(e) => {
                            setActiveLesson(lesson);
                        }} src={editIcon} alt="edit-icon" style={{ height: "24px", width: "24px" }} />
                    </a>
                    <a href={`#/riding-lesson/delete`}>
                        <img onClick={(e) => {
                            setDeleteLesson(lesson);
                        }} src={deleteIcon} alt="delete-icon" style={{ height: "24px", width: "24px" }} />
                    </a>
                    </div>
                </div>
                <hr />
                </>
                ))}
            </div>
        </div>
        </>
        );
}

export default AdminViewComponent