import { updateAllLessons } from "../pages/riding-lessons/state/riding-lessons.state";
import { getApiKey } from "../shared/auth-state/auth.state";
import { server } from "./server";

export async function getLessonsFromBe() {
    const xApiKey = getApiKey();
    if (!xApiKey) { console.error("no api key"); return; }
    const url = server + "/lessons/get-lessons.php"
    const headers = new Headers();
    headers.append("x-api-key", xApiKey);
    try {
        const r = await fetch(url, { headers})
        const resp = await r.json();
        updateAllLessons(resp);
    } catch (_) {
        return;
    }
}