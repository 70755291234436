import { RidingLesson } from "../pages/riding-lessons/state/riding-lessons.state";
import { AuthState, getApiKey } from "../shared/auth-state/auth.state";
import { server } from "./server";

export async function postApplyLessonFromBe(lesson: RidingLesson, type: "HORSE" | "PONY", user: AuthState) {
    const xApiKey = getApiKey();
    if (!xApiKey) { console.error("no api key"); return; }
    const url = server + "/lessons/post-apply-lesson.php"
    const headers = new Headers();
    headers.append("x-api-key", xApiKey);
    await fetch(url, { method: "POST", headers, body: JSON.stringify({ "lesson-uid": lesson?.uid, "user-uid": user?.uid, type }) });
    return;
}