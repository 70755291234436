import { BehaviorSubject, Observable, map } from "rxjs";
import { getKeyValidFromBe } from "../../api/get-key-valid.reqeuest";

export interface AuthState {
    uid?: string;
    email?: string;
    firstname?: string;
    lastname?: string;
    apikey?: string;
    apikeyExpiresOn?: Date;
    userLvl?: string;
    type?: "PONY" | "HORSE";
}

const authState$ = new BehaviorSubject<AuthState>({});

// check if api key is still valid
export async function checkApiKeyValid() {
    loading$.next(true);
    const state: AuthState = JSON.parse(localStorage?.getItem('rsui-auth') ?? "{}");
    if (!state?.apikey) { loading$.next(false); return; }
    getKeyValidFromBe(state?.apikey).then((r) => { authState$.next(r ? state : {}); }).finally(() => loading$.next(false));
}

// loading
const loading$ = new BehaviorSubject<boolean>(false);
export const authLoading$ = loading$.asObservable();
export function getAuthLoading() { return loading$?.getValue(); }
// update user
export function updateUser(_state: AuthState) {
    const state = { ..._state, ...{ apikeyExpiresOn: new Date(_state?.apikeyExpiresOn ?? "")} }
    localStorage.setItem('rsui-auth', JSON.stringify(state))
    authState$.next(state);
}

// get user
export const activeAuthUser$ = authState$.asObservable();
export function getActiveAuthUser(): AuthState {
    return authState$.getValue();
}

// user login state
export const isLoggedIn$: Observable<boolean> = authState$.pipe(map((state) => !!state?.apikey));
export function isLoggedIn(): boolean { return !!authState$?.getValue()?.apikey; }

// user role
export const userRole$: Observable<string | undefined> = authState$.pipe(map((state) => state?.userLvl));
export function getUserRole(): string | undefined { return authState$?.getValue()?.userLvl; }

// apikey
export const apikey$: Observable<string | undefined> = authState$.pipe(map((state) => state?.apikey));
export function getApiKey(): string | undefined { return authState$?.getValue()?.apikey; }