import { BehaviorSubject, tap } from "rxjs";
import { Toast } from 'primereact/toast';
import { useEffect, useRef } from "react";


interface AlertMsg {
    type: "success" | "info" | "warn" | "error";
    header: string;
}

export const msg$ = new BehaviorSubject<AlertMsg | null>(null);

export function Alert() {

    const toast = useRef(null);

    useEffect(() => {
        const sub = msg$.pipe(
            tap((msg) => {
                if (!toast?.current) return;
                if (!msg) return;
                (toast?.current as any)?.show({ severity: msg?.type, summary: msg?.header, sticky: false });
            }),
        ).subscribe();
        return () => { sub.unsubscribe(); }
    }, []);

    return <div style={{ position: "absolute", top: "50px", right: 0, height: 1, background: "none", zIndex: 99999999999999999 }}>
        <Toast ref={toast} position="top-center" />
    </div>
}

export default Alert;