import { server } from "./server";

export async function postRegister(user: {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    registerPwd: string;
}) {
    const url = server + "/auth/register.php"
    const headers = new Headers();
    const r = await fetch(url, { method: "POST", headers, body: JSON.stringify({ ...user, ...{ email: user?.email?.toLowerCase() }}) });
    return !(r?.status > 204);
}