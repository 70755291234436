import { getUserRole, userRole$ } from "../../shared/auth-state/auth.state";
import { useLocalState } from "../../util/use-local-state";
import AdminViewComponent from "./admin-view/AdminView.component";
import UserViewComponent from "./user-view/UserView.component";

export function RidingLessonsComponent() {
    const role = useLocalState(userRole$, getUserRole());
    
    if (role === "ADMIN" || role === "TEACHER") return <AdminViewComponent />
    if (role === "USER") return <UserViewComponent />
    return <></>;
}

export default RidingLessonsComponent