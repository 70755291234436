import { useEffect, useState } from "react";
import { useLocalState } from "../../../util/use-local-state";
import { RidingLesson, allLessons$, getAllLessons } from "../state/riding-lessons.state";
import { getLessonsFromBe } from "../../../api/get-lessons.request";
import { Button } from "primereact/button";
import { format } from "date-fns";
import { postApplyLessonFromBe } from "../../../api/post-apply-lesson";
import { getActiveAuthUser } from "../../../shared/auth-state/auth.state";
import { postCancelApplicationFromBe } from "../../../api/post-cancel-application.request";
import Loading from "../../../shared/loading/Loading.component";
import { msg$ } from "../../../shared/alert/Alert.component";
import { WEEKDAYS_SHORT } from "../../../shared/weekdays/weekdays";

export function UserViewComponent() {

    const lessons = useLocalState<RidingLesson[]>(allLessons$, getAllLessons());
    const [loading, setLoading] = useState<boolean>(false);
    
    useEffect(() => {
        setLoading(true);
        getLessonsFromBe().finally(() => setLoading(false));
    }, []);

    function handleApplyClick(lesson: RidingLesson, type: "HORSE" | "PONY") {
        if (!getActiveAuthUser()) return;
        setLoading(true);
        postApplyLessonFromBe(lesson, type, getActiveAuthUser())
        .catch(() => msg$.next({ type: "error", header: "Anmeldung fehlgeschlagen!" }))
        .then(() => {
            msg$.next({ type: "success", header: "Anmeldung erfolgreich!" });
            getLessonsFromBe().finally(() => setLoading(false));
        });
    }

    function handleCancelClick(lesson: RidingLesson) {
        if (!getActiveAuthUser()) return;
        setLoading(true);
        postCancelApplicationFromBe(lesson, getActiveAuthUser())
        .catch(() => msg$.next({ type: "error", header: "Abmeldung fehlgeschlagen!" }))
        .then(() => {
            msg$.next({ type: "info", header: "Abmeldung erfolgreich!" });
            getLessonsFromBe().finally(() => setLoading(false));
        });
    }

    return (<>
        {loading && <Loading />}
        <div>
                {lessons?.length < 1 && <h3>Aktuell sind keine Reitstunden geplant!</h3>}
                {lessons?.sort((a, b) => new Date(a?.date ?? "") > new Date(b?.date ?? "") ? 1 : -1)?.map((lesson, i) => (
                <>
                <div style={{ display: "flex", gap: "8px", margin: "8px 0" }}>
                    <div key={i} style={{ flex: 1, display: "flex", flexDirection: "column", gap: "8px" }} >
                        <span style={{ fontWeight: "bold" }}>{format(new Date(lesson?.date), "dd.MM.yyyy")} {lesson?.start}-{lesson?.end} ({WEEKDAYS_SHORT?.[new Date(lesson?.date)?.getDay()]})</span>
                        <span>{lesson?.teacher}</span>
                        <span>{lesson?.description}</span>
                        <span>Preis: {lesson?.price} €</span>
                        <span>Anmeldungen Pferd: {lesson?.bookedSlotsHorse ?? 0} / {lesson?.slotsHorse}</span>
                        <span>Anmeldungen Pony: {lesson?.bookedSlotsPony ?? 0} / {lesson?.slotsPony}</span>
                        {[...lesson?.usersHorse, ...lesson?.usersPony]?.some((u) => u?.uid === getActiveAuthUser()?.uid) && <span>Angemeldet als: {lesson?.usersHorse?.some((u) => u?.uid === getActiveAuthUser()?.uid) ? "Pferd" : "Pony"}</span>}
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "8px" }}>
                    {(lesson?.bookedSlotsHorse < lesson?.slotsHorse) &&(!([...lesson?.usersHorse, ...lesson?.usersPony])?.some((user) => user?.uid === getActiveAuthUser()?.uid)) && <Button label="Anmelden Pferd" disabled={lesson?.bookedSlotsHorse >= lesson?.slotsHorse} severity="success" onClick={() => handleApplyClick(lesson, "HORSE")} />}
                    {(lesson?.bookedSlotsPony < lesson?.slotsPony) &&(!([...lesson?.usersHorse, ...lesson?.usersPony])?.some((user) => user?.uid === getActiveAuthUser()?.uid)) && <Button label="Anmelden Pony" disabled={lesson?.bookedSlotsPony >= lesson?.slotsPony} severity="success" onClick={() => handleApplyClick(lesson, "PONY")} />}
                    {[...lesson?.usersHorse, ...lesson?.usersPony]?.some((user) => user?.uid === getActiveAuthUser()?.uid) && <Button label="Abmelden"  severity="danger" onClick={() => handleCancelClick(lesson)} />}
                </div>
                <hr style={{margin: "8px 0"}} />
                </>
            ))}
        </div>
    </>);
}

export default UserViewComponent