import { getApiKey } from "../shared/auth-state/auth.state";
import { server } from "./server";

export async function postActivateUser(userUid: string) {
    const xApiKey = getApiKey();
    if (!xApiKey) { console.error("no api key"); return; }
    const url = server + "/users/activate-user.php"
    const headers = new Headers();
    headers.append("x-api-key", xApiKey);
    const r = await fetch(url, { method: "POST", headers, body: JSON.stringify({ userUid }) });
    return r?.status <= 204;
}