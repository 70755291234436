import { useState } from "react";
import { RidingLesson } from "../../state/riding-lessons.state";
import { add, format, isBefore } from "date-fns";
import TimeDropDownComponent from "../../../../shared/time-dropdown/TimeDropDown.component";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { putLessonToBe } from "../../../../api/put-lesson.request";
import { msg$ } from "../../../../shared/alert/Alert.component";

export function LessonNewAdminComponent() {

    /* COMPONENT STATE */
    //const [date, setDate] = useState<string>(format(add(new Date(), { days: 1 }), "dd.MM.yyyy"));
    const [lesson, setLesson] = useState<RidingLesson>({
        date: new Date(add(new Date(), { days: 1 })),
        start: "00:00",
        end: "00:00",
        price: 0,
        description: "",
        teacher: "",
        slotsHorse: 0,
        bookedSlotsHorse: 0,
        slotsPony: 0,
        bookedSlotsPony: 0,
        usersHorse: [],
        usersPony: []
    });

    /* COMPONENT LOGIC */
    function formInvalid(lesson: RidingLesson): string[] {
        function timeInvalid(start: string, end: string): boolean {
            const [hh1, mm1] = start?.split(":");
            const [hh2, mm2] = end?.split(":");
            if (hh1 < hh2) return false;
            if (hh1 === hh2 && mm1 < mm2) return false;
            return true;
        }
        let err: string[] = [];
        if (isBefore(lesson?.date, new Date())) err.push("Datum in Vergangenheit");
        if (lesson?.teacher?.length < 1) err.push("Kein Lehrer angegeben");
        if (lesson?.slotsHorse < 1 && lesson?.slotsPony < 1) err.push("Keine Plätze angegeben");
        if (timeInvalid(lesson?.start, lesson?.end)) err.push("Zeiten nicht erlaubt")
        return err;
    }
    function handleSaveClick() {
        putLessonToBe(lesson)
        .catch(() => msg$.next({ type: "error", header: "Unterricht speichern fehlgeschlagen!" }))
        .then(() => msg$.next({ type: "success", header: "Unterricht erfolgreich gespeichert!" }))
        .then(() => {window.location.hash = "/riding-lessons"; });
    }

    /* COMPONENT TEMPLATE */
    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                <InputText style={{ width: "100%" }} value={format(lesson?.date, "yyyy-MM-dd")} type="date" lang="de" onChange={((e) => setLesson(({ ...lesson, ...{ date: new Date(e?.target?.value) }})))} />
                <TimeDropDownComponent hh={lesson?.start?.split(":")?.[0]} mm={lesson?.start?.split(":")?.[1]} onChange={(hh, mm) => setLesson({ ...lesson, ...{ start: `${hh}:${mm}` }})} />
                <TimeDropDownComponent hh={lesson?.end?.split(":")?.[0]} mm={lesson?.end?.split(":")?.[1]} onChange={(hh, mm) => setLesson({ ...lesson, ...{ end: `${hh}:${mm}` }})} />
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                <InputText style={{ width: "100%" }} placeholder="Lehrer" value={lesson?.teacher} onChange={(e) => setLesson({...lesson, ...{ teacher: e?.target?.value }})} />
                <InputText style={{ width: "100%" }} placeholder="Beschreibung" value={lesson?.description} onChange={(e) => setLesson({...lesson, ...{ description: e?.target?.value }})} />
                <InputNumber style={{ width: "100%" }} value={lesson?.price} onChange={(e) => setLesson({...lesson, ...{ price: e?.value ?? 0 }})} />
                <div>
                    Pferde: 
                    <InputNumber style={{ width: "100%" }} value={lesson?.slotsHorse} onChange={(e) => setLesson({...lesson, ...{ slotsHorse: e?.value ?? 0 }})} />
                </div>
                <div>
                    Ponys:
                    <InputNumber style={{ width: "100%" }} value={lesson?.slotsPony} onChange={(e) => setLesson({...lesson, ...{ slotsPony: e?.value ?? 0 }})} />
                </div>
            </div>
            <div>
                <Button disabled={formInvalid(lesson)?.length > 0} label="Speichern" onClick={handleSaveClick} />
                <div style={{ marginTop: "8px" }}>
                    {formInvalid(lesson)?.map((err, key) => <p key={key} style={{color: "red"}}>{err}</p>)}
                </div>
            </div>
        </div>
    );
}

export default LessonNewAdminComponent;