import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { msg$ } from "../../../shared/alert/Alert.component";
import { postChangePasswordInBe } from "../../../api/post-change-pwd.request";

export function ChangePasswordComponent() {
    
    const [pwds, setPwds] = useState<{ old: string; new1: string; new2: string }>({ old: "", new1: "", new2: "" });

    function handleSaveClick() {
        if (pwds?.new1 !== pwds?.new2) {
            msg$.next({ type: "warn", header: "Die neuen Passwörter stimmen nicht überein!" });
            return;
        }
        postChangePasswordInBe(pwds?.old, pwds?.new1, pwds?.new2)
            .catch(() => msg$.next({ type: "warn", header: "Passwort konnte nicht geändert werden!" }))
            .then((r) =>  r ? msg$.next({ type: "success", header: "Passwort erfolgreich geändert!" }) : msg$.next({ type: "warn", header: "Passwort konnte nicht geändert werden!" }));
    }

    return (
    <div>
        <div style= {{ display: "flex", gap: "8px" }}>
            <div>
                <p style={{ height: "50px", display: "flex", alignItems: "center" }}>Altes Passwort</p>
                <p style={{ height: "50px", display: "flex", alignItems: "center" }}>Neues Passwort</p>
                <p style={{ height: "50px", display: "flex", alignItems: "center" }}>Neues Passwort</p>
            </div>
            <div style={{ flex: 1 }}>
                <div style={{ height: "50px", display: "flex", alignItems: "center" }} >
                    <InputText placeholder="Altes Passwort" value={pwds?.old} onChange={(e) => setPwds({...pwds, ...{ old: e?.target?.value }})} />
                </div>
                <div style={{ height: "50px", display: "flex", alignItems: "center" }} >
                    <InputText placeholder="Neues Passwort" value={pwds?.new1} onChange={(e) => setPwds({...pwds, ...{ new1: e?.target?.value }})} />
                </div>
                <div style={{ height: "50px", display: "flex", alignItems: "center" }} >
                    <InputText placeholder="Neues Passwort" value={pwds?.new2} onChange={(e) => setPwds({...pwds, ...{ new2: e?.target?.value }})} />
                </div>
            </div>
        </div>
        <div style={{ display: "flex", gap: "8px", alignItems: "center", justifyContent: "center", marginTop: "16px" }}>
            <Button label="Speichern" severity="success" onClick={handleSaveClick} />
            <Button severity="danger">
                <a href={`#/my-profile`} style={{ color: "white", textDecoration: "none", display: "flex", alignItems: "center", gap: "8px" }}>
                    <span>Abbrechen</span>
                </a>
            </Button>
        </div>
    </div>);
}

export default ChangePasswordComponent;