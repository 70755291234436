import { getApiKey } from "../shared/auth-state/auth.state";
import { server } from "./server";

export async function postChangeNewUserPwd(pwd: string, userUid: string) {
    const xApiKey = getApiKey();
    if (!xApiKey) { console.error("no api key"); return; }
    const url = server + "/users/change-user-pwd.php"
    const headers = new Headers();
    headers.append("x-api-key", xApiKey);
    const r = await fetch(url, { method: "POST", headers, body: JSON.stringify({ userUid, pwd }) });
    return !(r?.status > 204);
}