import { updateAllUsers } from "../pages/users/state/users.state";
import { getApiKey } from "../shared/auth-state/auth.state";
import { server } from "./server";

export async function getUsersFromBe() {
    const xApiKey = getApiKey();
    if (!xApiKey) { console.error("no api key"); return; }
    const url = server + "/users/get-users.php"
    const headers = new Headers();
    headers.append("x-api-key", xApiKey);
    try {
        const r = await fetch(url, { headers})
        const resp = await r.json();
        updateAllUsers(resp);
    } catch (_) {
        return;
    }
}