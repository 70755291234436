import { useEffect, useState } from "react"
import { getUsersFromBe } from "../../api/get-users.request";
import { useLocalState } from "../../util/use-local-state";
import { User, allUsers$, getAllUsers, setActiveUser } from "./state/users.state";
import { InputText } from "primereact/inputtext";
import editIcon from '../../assets/icon_edit.svg';
import { Button } from "primereact/button";

export function UsersComponent() {

    /* COMPONENT STATE */
    const users = useLocalState<User[]>(allUsers$, getAllUsers());
    const [search, setSearch] = useState<string>("");

    /* COMPONENT INIT */
    useEffect(() => {
        getUsersFromBe();
    }, []);

    return (<div>
        <div>            
            <Button>
                <a href={`#/change-register-pwd`} style={{ color: "white", textDecoration: "none", display: "flex", alignItems: "center", gap: "8px" }}>
                        <img src={editIcon} alt="add-icon" style={{ height: "24px", width: "24px" }} /> <span>Registrierungspasswort</span>
                </a>
            </Button>
        </div>
        <hr style={{ margin: "4px 0" }} />
        <div>
            <InputText style={{ width: "100%" }} value={search} onChange={(e) => setSearch(e?.target?.value)} placeholder="Benutzer suchen" />
        </div>
        <div style={{marginTop: "16px"}}>
            {users?.filter((user) => user?.role === "USER" && `INAKTIV ${user?.firstName} ${user?.lastName}`?.toLowerCase()?.includes(search?.toLowerCase()))?.map((user, i) => (
                <>
                    <div style={{ display: "flex", gap: "8px", margin: "8px 0" }}>
                        <div key={i} style={{ flex: 1, display: "flex", flexDirection: "column", gap: "8px" }} >
                            <span style={{ fontWeight: "bold" }}>{!user?.active ? <>[INAKTIV]&nbsp;</> : null}{user?.firstName} {user?.lastName}</span>
                            <span>{user?.email}</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <a href={`#/user-edit`}>
                                <img onClick={(e) => setActiveUser(user)} src={editIcon} alt="edit-icon" style={{ height: "32px", width: "32px" }} />
                            </a>
                        </div>
                    </div>
                    <hr />
                </>
            ))}
        </div>
    </div>)
}

export default UsersComponent;