import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { postRegister } from "../../api/post-register.request";
import { msg$ } from "../../shared/alert/Alert.component";
import Loading from "../../shared/loading/Loading.component";

export function RegisterComponent() {

    const [form, setForm] = useState<{
        email: string;
        firstName: string;
        lastName: string;
        password: string;
        registerPwd: string;
    }>({ email: "", firstName: "", lastName: "", password: "", registerPwd: "" });
    
    const [loading, setLoading] = useState(false);

    function handleRegisterClick() {
        setLoading(true);
        postRegister(form).then((e) => {
            if (e) {
                msg$.next({ type: "success", header: "Registrierung efolgreich!" });
            } else {
                msg$.next({ type: "warn", header: "Registrierung fehlgeschlagen!" });
            }
        }).finally(() => setLoading(false));
    }

    return (
        <>
        {loading && <Loading />}
    <div className="card" style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <div>
            <InputText placeholder="Email" value={form?.email} onChange={(e) => setForm({ ...form, ...{ email: e?.target?.value }})} />
        </div>
        <div>
            <InputText placeholder="Vorname Reitschüler" value={form?.firstName} onChange={(e) => setForm({ ...form, ...{ firstName: e?.target?.value }})} />
        </div>
        <div>
            <InputText placeholder="Nachname Reitschüler" value={form?.lastName} onChange={(e) => setForm({ ...form, ...{ lastName: e?.target?.value }})} />
        </div>
        <div>
            <InputText placeholder="Passwort Reitschüler" value={form?.password} onChange={(e) => setForm({ ...form, ...{ password: e?.target?.value }})} />
        </div>
        <br /><hr/> <br />
        <div>
            <InputText placeholder="Registrierungs-Passwort" type="password" value={form?.registerPwd} onChange={(e) => setForm({ ...form, ...{ registerPwd: e?.target?.value }})} />
        </div>
        <div>
            <Button label="Registrieren" onClick={handleRegisterClick} />
        </div>
    </div>
    </>
    );
}

export default RegisterComponent;