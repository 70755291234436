const hours = Array.from(Array(24).keys())?.map((h) => h?.toString()?.padStart(2, '0'));
const minutes = Array.from(Array(60).keys())?.map((m) => m?.toString()?.padStart(2, '0'));

export function TimeDropDownComponent({hh, mm, onChange}: { hh: string, mm: string, onChange: (hh: string, mm: string) => void }) {
    return (
        <div style={{ width: "100%", display: "flex", alignItems: "center", gap: "2px" }}>
            <div className="select-wrapper">
                <select value={hh} onChange={(e) => onChange(e?.target?.value, mm)} style={{ flex: 1, border: "1px solid #ced4da", borderRadius: "6px", backgroundColor: "white", padding: "0.75rem" }}>
                    {hours?.map((h) => <option value={h}>{h}</option>)}
                </select>
            </div>
            <span>:</span>
            <div className="select-wrapper">
                <select value={mm} onChange={(e) => onChange(hh, e?.target?.value)} style={{ flex: 1, border: "1px solid #ced4da", borderRadius: "6px", backgroundColor: "white", padding: "0.75rem" }}>
                    {minutes?.map((m) => <option value={m}>{m}</option>)}
                </select>
            </div>
        </div>
    );
}

export default TimeDropDownComponent;