import { useEffect } from "react";
import { useLocalState } from "../../../../util/use-local-state";
import { RidingLesson, deleteLesson$, getDeleteLesson, setDeleteLesson } from "../../state/riding-lessons.state";
import { format } from "date-fns";
import { Button } from "primereact/button";
import { deleteLessonFromBe } from "../../../../api/delete-lesson.request";
import { msg$ } from "../../../../shared/alert/Alert.component";

export function LessonDeleteAdminComponent() {

    const lesson = useLocalState<RidingLesson | undefined | null>(deleteLesson$, getDeleteLesson());

    useEffect(() => { if (!lesson) { window.location.hash = "/riding-lessons"; } return () => { setDeleteLesson(null); }}, [lesson]);

    /* COMPONENT LOGIC */
    function handleYesClick() {
        if (lesson) {
            deleteLessonFromBe(lesson)
            .catch(() => msg$.next({ type: "error", header: "Unterricht löschen fehlgeschlagen!" }))
            .then(() => msg$.next({ type: "success", header: "Unterricht erfolgreich gelöscht!" }))
            .finally(() => { window.location.hash = "/riding-lessons"; });
        }
    }

    return (
        <div>
            <h3>Unterricht wirklich löschen?</h3>
            <p>Alle Anmeldungen gehen verloren!</p>        
            <hr style={{ margin: "8px 0" }} />    
            {lesson && 
            <div style={{  marginTop: "16px", flex: 1, display: "flex", flexDirection: "column", gap: "8px" }} >
                <span style={{ fontWeight: "bold" }}>{format(new Date(lesson?.date), "dd.MM.yyyy")} {lesson?.start}-{lesson?.end}</span>
                <span>{lesson?.teacher}</span>
                <span>Pferde-Plätze: {lesson?.slotsHorse}</span>
                <span>Pony-Plätze: {lesson?.slotsPony}</span>
            </div>}
            <hr style={{ margin: "8px 0" }} />
            <div style={{ display: "flex", gap: "8px" }}>
                <Button label="Ja" severity="success" onClick={handleYesClick} />
                <Button severity="danger">
                <a style={{ textDecoration: "none", color: "white" }} href={`#/riding-lessons`}>Nein</a>
                </Button>
            </div>
        </div>
    );
}

export default LessonDeleteAdminComponent;