import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { postChangeRegisterPwd } from "../../../api/post-change-register-pwd.request";
import { msg$ } from "../../../shared/alert/Alert.component";

export function ChangeRegisterPwdComponent() {

    const [pwd, setPwd] = useState<string>("");

    function handleBtnClick() {
        postChangeRegisterPwd(pwd).then((e) => {
            if (e) {
                msg$.next({ type: "success", header: "Registrierungspasswort efolgreich geändert!" });
            } else {
                msg$.next({ type: "warn", header: "Registrierungspasswort konnte nicht geändert werden!" });
            }
        })
    }
    
    return (<div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <InputText placeholder="Registrierungspasswort" value={pwd} onChange={(e) => setPwd(e?.target?.value)} />
        <Button label="Speichern" onClick={handleBtnClick} />
    </div>);
}

export default ChangeRegisterPwdComponent;